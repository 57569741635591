import React from 'react';
import ContactForm from '../ContactForm';
import './SubPagesStyles.css';
import { getEmail } from '../Utils/ContactUtility';
const PrivacyPolicy = () => {
    return (
        <div className="App-privacy-policy container">
            <header>
                <h1 style={{ textAlign: 'center' }}>Saffron Software's privacy policy</h1>
            </header>
            <br />
            <article>
                <h2>Personal data administrator</h2>
                The administrator of your personal data is:
                <ul>
                    <li>Saffron Software Ppppppppp Ssssssssss</li>
                    <li>Ffffffff 2/26</li>
                    <li>99-999 Wwwwwwww</li>
                    <li>Poland</li>
                    <li>{getEmail()}</li>
                    <li>TIN: 9950165555</li>
                    <li>REGON: 23657483753</li>
                </ul>
                <h2>Your privacy rights</h2>
                <h2>Submitting the contact form</h2>
                The following data is collected:
                <ul>
                    <li>Your full name</li>
                    <li>Your email address</li>
                </ul>
                The purpose of collecting the data is to allow us to reply to your inquiries.
            </article>
            <div style={{ maxWidth: '600px', justifyContent: 'center', alignItems: 'center' }}>
                <ContactForm />
            </div>
        </div>
    );
};

export default PrivacyPolicy;
