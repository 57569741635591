import React from 'react';
import { Icon } from '@iconify/react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { getEmail } from './Utils/ContactUtility';

const Footer = () => {
    return (
        <footer className="App-footer">
                <div className="row">
                    {/* <div className="col">
                        <Link to="/">
                            <img src={logo} width="350" alt="logo"></img>
                        </Link>
                    </div> */}
                    <div className="col">
                        <ul>
                            <li>Saffron Software Ppppppppp Ssssssssss</li>
                            <li>Ffffffff 2/26</li>
                            <li>99-999 Wwwwwwww, Poland</li>
                            <li>NIP 9999999999</li>
                        </ul>
                    </div>
                    <div className="col">
                        <ul>
                            <Link className="link" to="/privacy-policy">
                                Privacy policy
                            </Link>
                        </ul>
                    </div>
                    <div className="col">
                        <ul>
                            <li>{getEmail()}</li>
                        </ul>
                    </div>
                    <div className="col social-media">
                        <a
                            href="https://www.linkedin.com/company/saffron-software/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon className="icon" icon="mdi:linkedin" width="50" height="50" />
                        </a>
                        <a
                            href="https://www.facebook.com/saffronsoftwarenet"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon className="icon" icon="mdi:facebook" width="50" height="50" />
                        </a>
                    </div>
                </div>
                <div className="separator"></div>
                <span className="copyright">
                    <span>© {new Date().getFullYear()} Saffron Software </span>
                    <i>Flavoured by Saffron Software</i>
                </span>
        </footer>
    );
};

export default Footer;
