export function getEmail(params) {
    return (
        <button
            className="link"
            style={{
                border: 'none',
                background: 'none',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                fontSize: '11pt',
            }}
            onClick={sendEmail}
        >
            ✉ s-software@saffron-software.net
        </button>
    );
}

// export function getPhone() {
//     return (
//         <button
//             style={{
//                 border: 'none',
//                 color: 'white',
//                 background: 'none',

//                 cursor: 'pointer',
//                 fontSize: '11pt',
//             }}
//             onClick={dial}
//         >
//             📱 +48 999 999 999
//         </button>
//     );
// }

function sendEmail() {
    window.open('mailto:s-software@saffron-software.net');
}
// function dial() {
//     window.open('tel:+48999999999');
// }
