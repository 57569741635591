import './ContactInvitation.css';
import BtnGold from './Buttons/BtnGold.js';

import Modal from './Modal';
import React, { useState } from 'react';
import ContactForm from './ContactForm';

const ContactInvitation = () => {
    const [show, setShow] = useState(false);
    function callbackContactForm(show) {
        setShow(show);
    }
    return (
        <div className="App-contact-invitation noselect">
            <div className="container">
                <h1 style={{ margin: 5 }}>
                    <b>HOW CAN WE HELP YOU 😊?</b>
                </h1>
                <div>
                    <i>We'd love to hear from you </i>💬
                </div>
                <Modal show={show} onClose={() => setShow(false)}>
                    <ContactForm callback={() => callbackContactForm} />
                </Modal>
                <BtnGold
                    onPress={() => setShow(true)}
                    text="CONTACT US"
                    size="medium"
                    margin="40px"
                />
            </div>
        </div>
    );
};

export default ContactInvitation;
