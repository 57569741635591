import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import Home from './Components/SubPages/Home';
import PrivacyPolicy from './Components/SubPages/Privacy-Policy';

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
