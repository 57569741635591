import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';

function BtnGold(props) {
    return (
        <AwesomeButton
            onPress={props.onPress}
            size={props.size}
            disabled={props.disabled}
            type="secondary"
            style={{
                '--button-secondary-color': '#e8ba00',
                '--button-secondary-color-dark': '#892ca0',
                '--button-secondary-color-light': '#ffffff',
                '--button-secondary-color-hover': '#ffe57a',
                '--button-secondary-color-active': '#892ca0',
                '--button-secondary-border': '#892ca0',
                fontSize: '16px',
                margin: props.margin,
            }}
        >
            <b>{props.text}</b>
        </AwesomeButton>
    );
}

export default BtnGold;
