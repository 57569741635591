import React, { useEffect, useRef } from 'react';
import { Icon } from '@iconify/react';
import styles from './Modal.module.css';

const Modal = ({ modalStyle, children, show, onClose, backdropStyle }) => {
    const modalRef = useRef(null);
    useEffect(() => {
        if (show) {
            modalRef.current.classList.add(styles.visible);
        } else {
            modalRef.current.classList.remove(styles.visible);
        }
    }, [show]);
    return (
        <React.Fragment>
            <div ref={modalRef} style={backdropStyle} className={`${styles.modal__wrap}`}>
                <button
                    onClick={onClose}
                    style={{
                        width: 60,
                        height: 40,
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        margin: '1rem',
                    }}
                    className={styles.close__btn}
                >
                    <Icon icon="mdi:close" height="40px" width="40px" color="gray" className={styles.close__icon} />
                </button>
                <div style={modalStyle} className={styles.modal}>
                    {children}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Modal;
