import React from 'react';
import Collaboration from '../Collaboration';
import ContactInvitation from '../ContactInvitation';
import signet from '../../signet.svg';
import Header from '../Header';

const Home = () => {
    return (
        <div className="page App-home">
            <Header />
            <div className="App">
                <img src={signet} className="App-logo" alt="signet"></img>
                <p>
                    <b>UNDER CONSTRUCTION</b>
                </p>
            </div>
            <Collaboration />
            <ContactInvitation />
        </div>
    );
};

export default Home;
