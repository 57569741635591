import React from 'react';
import BtnSubmit from './Buttons/BtnGold';
import { useForm, ValidationError } from '@formspree/react';
import './ContactForm.css';
import { Link } from 'react-router-dom';

function ContactForm() {
    const [state, handleSubmit] = useForm('xleakgrn');
    const [count, setCount] = React.useState(0);

    function resizeTextArea(el) {
        el.style.height = 'auto';
        el.style.height = el.scrollHeight + 'px';
    }

    if (state.succeeded) {
        return <div style={{ padding: '40px' }}>Your message has been sent sucessfully! 🎉</div>;
    }
    return (
        <div className="App-contact-form container">
            <form onSubmit={handleSubmit}>
                <div>
                    <label>We'd love to hear from you!</label>
                    <div style={{ marginTop: '20px' }}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Full name"
                            required
                            maxLength="40"
                        />
                    </div>
                    <div>
                        <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder="Email address"
                            required
                            maxLength="320"
                        />
                        <ValidationError field="email" prefix="Email" errors={state.errors} />
                    </div>
                </div>
                <div>
                    <textarea
                        id="message"
                        className="message-box"
                        type="text"
                        name="message"
                        placeholder="Message"
                        required
                        maxLength="2000"
                        onChange={(e) => {
                            setCount(e.target.value.length);
                            resizeTextArea(e.target);
                        }}
                    />
                </div>
                <div id="counter1">
                    {count}/{document.getElementById('message')?.maxLength ?? 2000}
                </div>
                <BtnSubmit type="submit" disabled={state.submitting} text="SEND" />
                <div style={{ marginTop: '20px' }}>
                    <i style={{ color: 'gray', fontSize: '12pt' }}>
                        By clicking "Send" you accept terms of{' '}
                        <Link className="link" to="/privacy-policy">
                            Privacy policy
                        </Link>
                    </i>
                </div>
            </form>
        </div>
    );
}

export default ContactForm;
