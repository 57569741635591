import React from 'react';
import logo from '../logo.svg';

const Header = () => {
    // const handleScroll = () => {
    //     let img = document.getElementById('logo');

    //     if (window.scrollY > 100) {
    //         img.src = whiteSignet;
    //     } else {
    //         img.src = logo;
    //     }
    // };

    // window.addEventListener('scroll', handleScroll);

    return (
        <header className="App-header">
            <img id="logo" className="logo" src={logo} width="300" alt="logo"></img>
        </header>
    );
};

export default Header;
