import React from 'react';
import './Collaboration.css';
import lodasoftLogo from '../Collaborators/lodasoft.png';
import psmmLogo from '../Collaborators/psmm.png';
import ttcLogo from '../Collaborators/TTC.png';

const Collaboration = () => {
    return (
        <header className="App-collaboration">
            <div className="container noselect">
                <h1>AWESOME PEOPLE WE COLLABORATED WITH*:</h1>
                {/* <CurlyBrace className="curly-brace" mirror={true} /> */}
                    <div className="company-container">
                        <a href="http://www.lodasoft.com" target="_blank" rel="noreferrer">
                        <img
                            className="company-logo"
                            src={lodasoftLogo}
                            alt="Lodasoft logo"
                        ></img>
                        </a>
                        <a href="https://psmm.pl" target="_blank" rel="noreferrer">
                        <img
                            className="company-logo"
                            src={psmmLogo}
                            alt="Press Service Monitoring Mediów logo"
                        ></img>
                        </a>
                        <a href="https://travcorp.pl" target="_blank" rel="noreferrer">
                        <img
                            className="company-logo"
                            src={ttcLogo}
                            alt="Travcorp Poland logo"
                        ></img>
                        </a>
                    </div>
                {/* <CurlyBrace className="curly-brace" /> */}
                <div className="separator-black">
                    <div style={{ textAlign: 'right', color: 'gray' }}>*no diggity</div>
                </div>
            </div>
        </header>
    );
};

export default Collaboration;
